// Generated by Framer (1b127cb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["RFsuR4TbV", "QaVEHUzU1"];

const variantClassNames = {QaVEHUzU1: "framer-v-1eezfr9", RFsuR4TbV: "framer-v-1abf7j0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Learn More Open": "QaVEHUzU1", "Variant 1": "RFsuR4TbV"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "RFsuR4TbV", title: adHMRVCDg = "Learn more", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "RFsuR4TbV", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-cMxQk", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1abf7j0", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"RFsuR4TbV"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-b0dc4f7b-cdca-4b14-99f5-346588fa8d9c, rgb(28, 48, 84)) /* {\"name\":\"Dark Blue\"} */", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-618deff4-6ede-455d-ae1e-be54ead4b7eb, rgb(248, 248, 245))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}} transition={transition} {...addPropertyOverrides({QaVEHUzU1: {"data-framer-name": "Learn More Open"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "Q1VTVE9NO0YzNyBOZXVybyBNZWRpdW0=", "--framer-font-family": "\"F37 Neuro Medium\", sans-serif", "--framer-font-size": "26px", "--framer-line-height": "1.25em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-1of0zx5)"}}>Learn more</motion.h2></React.Fragment>} className={"framer-1966mj5"} data-framer-name={"Learn more"} fonts={["CUSTOM;F37 Neuro Medium"]} layoutDependency={layoutDependency} layoutId={"fvgXU9ggU"} style={{"--extracted-1of0zx5": "rgb(73, 89, 118)", "--framer-paragraph-spacing": "0px"}} text={adHMRVCDg} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-cMxQk [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cMxQk .framer-1is6m7h { display: block; }", ".framer-cMxQk .framer-1abf7j0 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 52px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 300px; }", ".framer-cMxQk .framer-1966mj5 { flex: none; height: auto; overflow: visible; position: relative; white-space: pre-wrap; width: 342px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-cMxQk .framer-1abf7j0 { gap: 0px; } .framer-cMxQk .framer-1abf7j0 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-cMxQk .framer-1abf7j0 > :first-child { margin-left: 0px; } .framer-cMxQk .framer-1abf7j0 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 300
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QaVEHUzU1":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"adHMRVCDg":"title"}
 */
const FramerEOsfUC5W9: React.ComponentType<Props> = withCSS(Component, css, "framer-cMxQk") as typeof Component;
export default FramerEOsfUC5W9;

FramerEOsfUC5W9.displayName = "Learn More FAQ";

FramerEOsfUC5W9.defaultProps = {height: 52, width: 300};

addPropertyControls(FramerEOsfUC5W9, {variant: {options: ["RFsuR4TbV", "QaVEHUzU1"], optionTitles: ["Variant 1", "Learn More Open"], title: "Variant", type: ControlType.Enum}, adHMRVCDg: {defaultValue: "Learn more", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerEOsfUC5W9, [{family: "F37 Neuro Medium", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/EOsfUC5W9:default", url: "assets/3SfGfchEObNECbER3LO0PIlRrs.otf"}, url: new URL("assets/3SfGfchEObNECbER3LO0PIlRrs.otf", import.meta.url).href}])